.contFooter{
    display: flex;
    justify-content: center;
    background-color: #047ab0;
    width: 100%;
    height: 50px;
    margin-bottom: 0;
}

.contFooter p{
    margin-bottom: 2px;
    margin-top: 0px;
    font-weight: bold;
    text-align: center;
    color: #000000;
}