.contGeralQsomos{
    display: flex;
    justify-content: center;
}

.contGeralQsomos img{
    max-width: 100%;
    height: 620px;
}

@media screen and (max-width: 1280px) {
    .contGeralQsomos img {
        max-width: 100%;
        max-height: auto;
    }
}


