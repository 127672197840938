.header{
    padding: 10px;
    width: 100%;
    background-color: #047ab0;
}

.headerGeral{
    
}

.headerGeral img{
    width: 130px;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: 5px;
}

.headerMenu{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.headerMenu a{
    font-weight: bold;
    text-align: right;    
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
    color: #080808;
    transition: color 0.7s;
}

.headerGeral a:hover{
    color: #09c9f4;
}



