.contGeralPortfolio {
    display: flex;
    justify-content: center;
}

.contGeralPortfolio img {
    max-width: 100%;
    height: 620px;
    
}


@media screen and (max-width: 1280px) {
    .contGeralPortfolio img {
        max-width: 100%;
        max-height: auto;
    }
}