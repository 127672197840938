.posicao {
    display: flex;
    justify-content: center;
    z-index: auto;
    height: 630px;
    background-image: url('../../assets/background/background_carrossel_1.jpg');
    
}

.contCarrossel {
    position: relative;

    display: -webkit-box;
    display: flex;

    width: 980px;
    height: 620px;
    -webkit-box-pack: initial;
    justify-content: center;
}

.itemCarrossel img {
    width: 1400px;
    height: 620px;
    margin-top: 5px;
    padding: auto;
    opacity: 60%;
    object-fit: cover;
}

.itemCarrossel h1 {
    color: #ff4704;
    font-weight: bold;
}


@media screen and (max-width: 1280px) {       
    .itemCarrossel img{
        max-width: 100%;
        margin-top: 5px;
        padding: auto;
    }
}

